<template>
  <div class="van-main stock-detail">
    <div class="area-title">
      <p class="title">
        货物明细
      </p>
    </div>
    <div class="van-common-detail">
      <van-cell-group>
        <van-cell title="柜号" :value="utils.isEffectiveCommon(stockForm.cabinetNo)" />
        <van-cell title="国家" :value="utils.isEffectiveCommon(stockForm.country)" />
        <van-cell title="原产地" :value="utils.isEffectiveCommon(stockForm.countryOfOrigin)" />
        <van-cell title="HS编码" :value="utils.isEffectiveCommon(stockForm.hsCode)" />
        <van-cell title="货物名称" :value="utils.isEffectiveCommon(stockForm.cargoName)" />
        <van-cell title="发货件数" :value="utils.isEffectiveCommon(stockForm.pieceNumber)" />
        <van-cell title="实发重量（KG）" :value="utils.moneyFormat(stockForm.actualWeight)" />
        <van-cell title="原币总金额（USD）" :value="utils.moneyFormat(stockForm.money)" />
      </van-cell-group>
    </div>
    <div class="van-common-detail">
      <!-- <div class="table">
        <div v-for="(item, index) in goodsList" :key="item.id" class="tr">
          <div class="fl fl-index">
            {{ index + 1 }}
          </div>
          <div class="fl new-fl">
            <div class="flex-wrap">
              <div class="fl-flex">
                <div class="fl-flex-left">
                  <span>品名：</span><span>{{ item.productName }}</span>
                </div>
              </div>
              <div class="fl-flex">
                <div class="fl-flex-left">
                  <span>账面件数：</span><span>{{ item.pieceNumber }}</span>
                </div>
                <div class="fl-flex-right">
                  <span>账面重量（KG）：</span><span>{{ item.weight }}</span>
                </div>
              </div>
              <div class="fl-flex">
                <div class="fl-flex-left">
                  <span>实际件数：</span><span>{{ item.actualPieceNumber }}</span>
                </div>
                <div class="fl-flex-right">
                  <span>实际重量（KG）：</span><span>{{ item.actualWeight }}</span>
                </div>
              </div>
              <div class="fl-flex">
                <div class="fl-flex-left">
                  <span>可提件数：</span><span>{{ item.extractableNumber }}</span>
                </div>
                <div class="fl-flex-right">
                  <span>认定单价（元）：</span><span>{{ item.packageUnitPrice }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix" />
        </div>
      </div> -->
      <van-list>
        <div v-for="(item,index) in goodsList" :key="index" class="contract-item">
          <van-cell :title="index+1" class="title" />
          <van-cell title="品名" :value="utils.isEffectiveCommon(item.productName)" />
          <van-cell title="账面件数" :value="utils.isEffectiveCommon(item.pieceNumber)" />
          <van-cell title="实际件数" :value="utils.isEffectiveCommon(item.actualPieceNumber)" />
          <van-cell title="账面重量（KG）" :value="utils.moneyFormat(item.weight)" />
          <van-cell title="实际重量（KG）" :value="utils.moneyFormat(item.actualWeight)" />
          <van-cell title="可提件数" :value="utils.isEffectiveCommon(item.extractableNumber)" />
          <van-cell title="认定单价（元）" :value="utils.isEffectiveCommon(item.packageUnitPrice)" />
        </div>
      </van-list>
    </div>
    <back-button />
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, uploader } from 'vant'
import BackButton from '@/components/back-button'
export default {
  components: {
    BackButton,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [uploader.name]: uploader
  },
  data () {
    return {
      id: this.$route.query.id,
      rowId: this.$route.query.rowId,
      stockForm: {},
      goodsList: []
    }
  },
  computed: {
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.api.stock.detail(this.$route.query.id).then(result => {
        const newList = result.data.value.containerList.filter(item => {
          if (Number(item.id) === Number(this.rowId)) {
            return item
          }
        })
        this.stockForm = newList[0].pickUpInfoVo
        this.goodsList = newList[0].goodsList
        console.log(this.goodsList)
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
.stock-detail {
  margin-bottom: 60px;
  .table {
    font-size: 14px;
    margin: auto 10px;
    padding-bottom: 60px;
    .th {
      background-color: rgb(242, 242, 242);
      border: 1px solid rgb(215, 215, 215);
      border-radius: 5px 5px 0px 0px;
      height: 46px;
      text-align: center;
      line-height: 46px;
      font-weight: 700;
      >div:first-child {
        width: 10%;
      }
      >div:nth-child(2) {
        width: 90%;
      }
    }
    .tr {
      display: flex;
      text-align: center;
      font-weight: 400;
      border: 1px solid rgb(215, 215, 215);
      background-color: rgb(242, 242, 242);
      vertical-align: middle;
      align-items: center;
      .flex-wrap > .fl-flex{
        line-height: 100%;
        display: flex;
      }
      .fl-flex-left{
        line-height: normal !important;
        word-wrap: break-word;
        text-align: left;
        padding-left: 30px;
        flex: 2;
        overflow: hidden;
      }
      .fl-flex-right{
        line-height: normal !important;
        word-wrap: break-word;
        text-align: left;
        padding-left: 10px;
        flex: 3;
        overflow: hidden;
        >span:nth-child(2) {
          min-width: 20px;
        }
      }
      >div:first-child {
        width: 10%;
      }
      >div:nth-child(2) {
        width: 90%;
        padding: 20px 0;
        background: #fff;
      }
    }
    .van-divider {
      margin-top: 0px;
    }
  }
  .contract-item{
    border:1px solid #ccc;
    margin: 10px 10px;
    border-radius: 4px;
    .title {
      background: #F3F4F8;
      .van-cell__title{
        flex:1;
        text-align: center;
        font-size: 18px;
        color: #459ae9;
        font-weight: bold;
      }
    }
  }
}
</style>
