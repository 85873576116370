var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main stock-detail" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-cell-group",
            [
              _c("van-cell", {
                attrs: {
                  title: "柜号",
                  value: _vm.utils.isEffectiveCommon(_vm.stockForm.cabinetNo)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "国家",
                  value: _vm.utils.isEffectiveCommon(_vm.stockForm.country)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "原产地",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.stockForm.countryOfOrigin
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "HS编码",
                  value: _vm.utils.isEffectiveCommon(_vm.stockForm.hsCode)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "货物名称",
                  value: _vm.utils.isEffectiveCommon(_vm.stockForm.cargoName)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "发货件数",
                  value: _vm.utils.isEffectiveCommon(_vm.stockForm.pieceNumber)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "实发重量（KG）",
                  value: _vm.utils.moneyFormat(_vm.stockForm.actualWeight)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "原币总金额（USD）",
                  value: _vm.utils.moneyFormat(_vm.stockForm.money)
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-list",
            _vm._l(_vm.goodsList, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "contract-item" },
                [
                  _c("van-cell", {
                    staticClass: "title",
                    attrs: { title: index + 1 }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "品名",
                      value: _vm.utils.isEffectiveCommon(item.productName)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "账面件数",
                      value: _vm.utils.isEffectiveCommon(item.pieceNumber)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "实际件数",
                      value: _vm.utils.isEffectiveCommon(item.actualPieceNumber)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "账面重量（KG）",
                      value: _vm.utils.moneyFormat(item.weight)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "实际重量（KG）",
                      value: _vm.utils.moneyFormat(item.actualWeight)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "可提件数",
                      value: _vm.utils.isEffectiveCommon(item.extractableNumber)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "认定单价（元）",
                      value: _vm.utils.isEffectiveCommon(item.packageUnitPrice)
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 货物明细 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }